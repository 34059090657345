import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, maxLength, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {printBarcodes} from "@/utils/barcode";

export default {
  name: 'stores.edit',
  mixins: [validationMixin],
  data() {
    return {
      translations: [],
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    translations: {
      $each: {
        title: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        description: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(60)
        }
      }
    }
  },
  created() {
    this._applyData();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isStoreCreating: 'stores/isStoreCreating',
      store: 'stores/store',
    }),
    ...mapGetters([
      'languages',
    ]),
  },
  methods: {
    ...mapActions({
      createStore: 'stores/STORE_REQUEST_CREATE',
    }),
    _applyData() {
      this.translations = this.languages.map(langItem => {
        return {
          locale: langItem.translationKey,
          title: '',
          description: '',
        }
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    translationsDescriptionErrors(index) {
      let error = [];
      if (!this.$v.translations.$each[index].description.$dirty) {
        return error;
      }
      if (!this.$v.translations.$each[index].description.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.translations.$each[index].description.minLength) {
        error.push(this.$t('validationMax',{count: 2}));
      }
      if (!this.$v.translations.$each[index].description.maxLength) {
        error.push(this.$t('validationMax',{count: 60}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let payload = {};
        this.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title,
            description: item.description,
          }
        });

        this.createStore(payload).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'stores.list'}).catch(()=> {console.log()})

          this.getBarcodes();

        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    },
    getBarcodes() {
      printBarcodes([ this.store.guid ]);
    }
  }
}
